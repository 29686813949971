import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';

import Hero from '../components/Hero/index';
import Tag from '../components/Locations/Tag';
import Layout from '../components/Layouts/Inner';
import SEO from '../components/SEO';
import ContainerFixed from '../components/Layouts/ContainerFixed';

import PressReviews from '../components/PressReviews';
import Process from '../components/Process';
import Section from '../components/Section';
import AppDownload from '../components/AppDownload';
import ServicesInfo from '../components/Services/ServicesInfo';
import Professionals from '../components/Testimonial/Professionals';
import Faq from '../components/Collapse/Faq';
import Testimonials from '../components/Testimonial/Testimonials';

export const query = graphql`
  query($slug: String!) {
    location: contentfulLocations(slug: { eq: $slug }) {
      location
      slug
      heroTitle
      heroImage {
        file {
          url
        }
      }
      heroText
      areasCovered
      childContentfulLocationsMainContentRichTextNode {
        id
        json
      }
      frequentlyAskedQuestions {
        id
        title
        childContentfulAccordionBodyRichTextNode {
          id
          json
        }
        __typename
      }
      cleaningProfessionals {
        id
        name
        image {
          file {
            url
            fileName
            contentType
          }
        }
        childContentfulCarouselTextRichTextNode {
          id
          json
        }
        __typename
      }
    }
  }
`;

const LocationTemplate = ({ data: { location } }) => (
  <Layout>
    <SEO title={location.location} />
    <Hero
      image={location.heroImage ? location.heroImage.file.url : null}
      heading={location.heroTitle}
      showReviews
    >
      {location.heroText && <p>{location.heroText}</p>}
      <div className="tags">
        <p>
          {location.areasCovered.map((area) => (
            <Tag key={area}>{area}</Tag>
          ))}
        </p>
      </div>
    </Hero>
    <PressReviews />
    <br />
    <Section bgWhite containedText>
      {documentToReactComponents(location.childContentfulLocationsMainContentRichTextNode.json, {
        renderNode: {
          [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
        },
      })}
    </Section>
    <br />
    <Process />
    <ServicesInfo />
    <Professionals
      heading={`Meet some of your local and tested ${location.location} and surrounding area cleaners`}
      data={location.cleaningProfessionals}
    />
    <ContainerFixed>
      {location.frequentlyAskedQuestions && <Faq data={location.frequentlyAskedQuestions} />}
      <Testimonials />
      <AppDownload />
    </ContainerFixed>
  </Layout>
);

LocationTemplate.propTypes = {
  data: PropTypes.shape({
    location: PropTypes.object,
  }),
};

LocationTemplate.defaultProps = {
  data: {},
};

export default LocationTemplate;
